<template>
  <div class="settings" style="background-color: #f4f5f7; ">
    <template v-if="showEmojiModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-container">
            <div class="close-div">
              <img @click="showEmojiModal = false" style="cursor:pointer;width: 24px; height: 24px;"
                   :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
            </div>
            <div class="modal-inner">
              <p class="modal-header">Emoji Seç</p>

              <div>
                <div style="display: flex;flex-wrap: wrap;">
                  <div v-for="emoji in data.info.emojis" class="mb-2" style="font-size: 30px; cursor: pointer; height: 50px; width: 50px;" @click="copyEmoji(emoji.text)">
                    {{emoji.text}}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </transition>
    </template>

    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">

      <div class="content" :style="{ 'min-height': windowHeight - 110 + 'px; ' }">


        <div class="content-header">
          <p class="content-header-text">Editör Ekranı</p>
        </div>
        <p style="clear: both;"></p>


        <div v-if="data.type === 'logs'" class=" content-white" style="padding:32px; padding-bottom: 200px;">
            <div style="display: flex;  ">
                <div style="flex: 1">
                    <button @click="goBack" :disabled="loading" class="editGreenButton hoverGray" style="background-color: #5e6b79">< Geri Dön</button>
                </div>
            </div>

            <div class=" content-white" style="margin-top:32px;padding-top: 20px;">

                <div class="chatrow" style="border: none; padding-bottom:0; margin-bottom: 0; height: 30px; color: #2d3640">
                    <div style="flex: 130; padding-left:20px; font-size: 16px;" class="title">
                        Tarih
                    </div>
                    <div style="flex: 430;padding-left:12px; font-size: 16px;" class="title">
                        İşlem
                    </div>
                    <div style="flex: 130;padding-left:12px; font-size: 16px;" class="title">
                        Kredi
                    </div>
                </div>

                <div v-for="editorLog in editorLogs" class="chatrow">
                    <div style="flex: 130; padding-left:20px; font-size: 16px;" class="chatrow-item-date">
                        {{editorLog.created_at}}
                    </div>
                    <div style="flex: 430;padding-left:12px; font-size: 16px;" class="chatrow-item-description">
                        {{ editorLog.type }} - {{ editorLog.what }}
                    </div>
                    <div :style="{'flex': '130','padding-left':'12px','font-size':'16px','color': editorLog.credit < 0 ? '#f23434' : '#6dce6a'}" class="chatrow-item-total">
                        {{ editorLog.credit }}
                    </div>
                </div>
            </div>


            <div v-if="editorLogsseemorediv && !showLoader" @click="getLogsOffset()"
                 style="cursor: pointer; margin:20px 0;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
              Daha fazla gör +
            </div>
            <loader v-if="showLoader"></loader>

        </div>


        <div v-if="data.type === 'feedback'" class=" content-white" style="padding:32px; padding-bottom: 200px;">

          <div style="display: flex;  ">
            <div style="flex: 1">
              <button @click="goBack" :disabled="loading" class="editGreenButton hoverGray" style="background-color: #5e6b79">< Geri Dön</button>
            </div>
          </div>


          <div style="width: 555px; margin-top:44px; ">
            <h4>Bu işle ilgili bir sonuca varmakta zorlanıyorsan, alttaki alana zorlandığın kısımları ve geliştirme önerlerini yazabilirsin.</h4>
           <textarea v-model="comment" style="width: 455px; height: 100px; margin-top: 24px;"></textarea>
            <button @click="unassign" class="super-button-green"  :disabled="loading" style="margin-top:32px;max-width: 260px!important;width: 260px!important;">Yorumu Gönder ve Bu İşi Atla</button>
          </div>



        </div>

        <div v-if="data.type === 'home'" class=" content-white" style="padding-top: 20px;">
          <div class="chatrow" style="border: none; padding-bottom:0; margin-bottom: 0; height: 30px; color: #2d3640">
            <div style="flex: 15; padding-left:20px; font-size: 16px;" class="title">
              Toplam
            </div>
            <div style="flex: 15;padding-left:12px; font-size: 16px;" class="title">
              Yapılan Ödeme
            </div>
            <div style="flex: 15;padding-left:12px; font-size: 16px;" class="title">
              Güncel Bakiye
            </div>
            <div style="flex: 15; padding-left:12px; font-size: 16px;" class="title">
             İşlemler
            </div>
          </div>

          <div class="chatrow">
            <div style="flex: 15; padding-left:20px; font-size: 16px;" >
              <p style="font-size: 20px; margin-top: 10px; color: #2d3640">
                {{creditInfo.total.credit}} Kredi
              </p>
              <p v-if="creditInfo.total.tl"  style="font-size: 12px; margin-top: 10px;">
                {{creditInfo.total.tl.toFixed(2)}} TL
              </p>
            </div>
            <div style="flex: 15; padding-left:20px; font-size: 16px;" >
              <p style="font-size: 20px; margin-top: 10px; color: #2d3640">
                  {{creditInfo.payed.credit}} Kredi
              </p>
              <p v-if="creditInfo.payed.tl"  style="font-size: 12px; margin-top: 10px;">
                {{creditInfo.payed.tl.toFixed(2)}} TL
              </p>
            </div>
            <div style="flex: 15; padding-left:20px;" >
              <p style="font-size: 24px; margin-top: 10px; color: #2d3640">
                {{creditInfo.current.credit}} Kredi
              </p>
              <p v-if="creditInfo.current.tl"  style="font-size: 12px; margin-top: 10px;">
                {{creditInfo.current.tl.toFixed(2)}} TL
              </p>
            </div>
            <div style="flex: 15; padding-left:12px; font-size: 16px;" class="chatrow-item-total">
                <button @click="getLogsOffset" class="editButton">İşlem Detayları</button>
            </div>

          </div>


          <div style="margin: 20px auto; text-align: center">
            <button :disabled="loading" @click="start" class="super-button-green">Yeni İş Al</button>
          </div>





        </div>


        <div v-if="data.type === 'gig'" class=" content-white" style="padding:32px; padding-bottom: 200px;">


          <div style="display: flex;  ">
            <div style="flex: 1">
              <button @click="goBack" :disabled="loading" class="editGreenButton hoverGray" style="background-color: #5e6b79">< Geri Dön</button>
            </div>
            <div style="flex: 1; text-align: center">
              <button @click="sendFeedback" class="editGreenButton editPinkButton" style="background-color: #ff0042" :disabled="loading">Hata veya Öneri Bildir</button>
            </div>
            <div style="flex: 1; text-align: right;">
              <button @click="unassign" class="editGreenButton"  :disabled="loading">Bu İşi Atla -></button>
            </div>
          </div>


          <div style="width: 700px; margin-top:44px; ">


            <div class="helpTypeLogicBurada" v-if="data.help_type === 'title'">
              <label style="font-size: 32px; margin-bottom: 10px; margin-top: 20px;">İş İlanı Başlığı</label>
              <textarea style="width: 777px;font-size: 18px;" readonly >{{data.info.title}}</textarea>
            </div>
            <div class="helpTypeLogicBurada" v-else-if="data.help_type === 'description'">
              <label style="font-size: 32px; margin-bottom: 10px; margin-top: 20px;">İş İlanı Açıklaması</label>
              <textarea style="width: 700px;font-size: 18px; height: 350px" readonly >{{data.info.description}}</textarea>
            </div>
            <div class="helpTypeLogicBurada" v-else-if="data.help_type === 'buyer_requirements'">
              <label style="font-size: 32px; margin-bottom: 10px; margin-top: 20px;">Siparişi Başlatmak İçin Gerekenler</label>
              <textarea style="width: 700px;font-size: 18px; height: 200px" readonly >{{data.info.description}}</textarea>

              <div style="margin-top: 20px">

                <label>Kategori:</label>
                <h5 style="color: #2d3640;" >{{data.info.gig_category_name}} -> {{data.info.gig_category_sub_name}}</h5>
                <div class="hiddendiv10"></div>
              </div>
            </div>
            <div class="helpTypeLogicBurada" v-else-if="data.help_type === 'portfolio'">

              <div style="cursor: pointer" @click="showPortfolioDetailModal(data.info.portfolio)">

                <div style="position: relative; margin-top: 20px;  border-radius: 2px;">
                  <img style="width: 700px!important;" class="user-portfolio-image" :src="data.info.portfolio.image_url_small"/>
                  <img v-if="data.info.portfolio.file_type === 'video' || data.info.portfolio.file_type === 'audio'" class="user-portfolio-video-icon" src="https://gcdn.bionluk.com/site/icon/ic_play_circle_fill.svg"/>
                </div>
                <div>
                  <button class="super-button-light-small">Resmi Büyült</button>
                </div>
              </div>

              <div style="margin-top: 20px">

                <label>İlan Başlığı:</label>
                <h5 style="color: #2d3640;" >Ben, {{data.info.portfolio.gig_title}}</h5>
                <div class="hiddendiv10"></div>
                <label>Kategori:</label>
                <h5 style="color: #2d3640;" >{{data.info.portfolio.gig_category_name}} -> {{data.info.portfolio.gig_category_sub_name}}</h5>
              </div>
            </div>

            <div class="helpTypeLogicBurada" v-else-if="data.help_type === 'content'">

              <div style=" position: relative">
                <template v-if="codecError">
                 <div style="border:1px solid #e1e1e1;width: 700px; height: 391px; display: flex; align-items: center; justify-content: center">
                   <h3>VIDEO HATALI, OYNATILAMIYOR</h3>
                 </div>
                </template>
                <template v-else>
                  <img v-if="!activatedIframe" :style="'width:100%;object-fit:cover'" :src="data.info.content.image_url">
                  <div class="playIcon"
                       v-if="!activatedIframe &&  data.info.content.video_url && ( data.info.content.file_type === 'audio' ||  data.info.content.file_type === 'video')">
                    <img class="hoveropa" @click="activateIframe"
                         :src="$root.CDN_SITE + 'icon/ic_play_circle_fill.svg'">
                  </div>

                  <div v-if="videoLoader &&  data.info.content.video_url && ( data.info.content.file_type === 'audio' ||  data.info.content.file_type === 'video')" >
                    <img :style="'width:100%;object-fit:cover'" :src="data.info.content.image_url">
                    <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%); z-index: 44;">
                      <loader style="height: 30px;width: 30px;"></loader>
                    </div>
                  </div>
                </template>



                <div v-if="activatedIframe">
                  <iframe
                    @load="deactivateIframeLoader()"
                    v-show="!videoLoader"
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    width="700" height="394"
                    frameborder="0"
                    scrolling="no"
                    :src="'https://api.bionluk.com/general/video/' +  data.info.content.upload_id+'x'+ data.info.content.cover_id +'/gig_content/'"
                  >
                  </iframe>
                </div>


              </div>

              <div style="margin-top: 20px">
                <label>İlan Başlığı:</label>
                <h5 style="color: #2d3640;" >Ben, {{data.info.content.gig_title}}</h5>
                <div class="hiddendiv10"></div>
                <label>Kategori:</label>
                <h5 style="color: #2d3640;" >{{data.info.content.gig_category_name}} -> {{data.info.content.gig_category_sub_name}}</h5>
                <div class="hiddendiv10"></div>
                <label>Tüm Portfolyosu:</label>
                <h5><a :href="'/portfolyo/'+data.info.content.username" target="_blank" style="color: #fe5b5c">Portfolyo Sayfasına Git</a></h5>
              </div>
            </div>

            <div v-if="data.help_type !== 'portfolio' && data.help_type !== 'content'" style="float: left; margin-top: 24px; ">


              <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">Üstteki içerikte alttakilerden bir veya birkaç tanesi mevcut mudur?</h4>
              <p v-for="(disapproval, index) in data.disapprovals">

                <button
                  :disabled="loading"
                  @click="selectDisapproval(index, disapproval.id)"
                  :class="[disapproval.selected ? 'selected' : '', 'editButton']"
                >
                  Seç
                </button>



                    <span style="color: #2d3640; font-weight: 500;">{{disapproval.title}}</span>
                  <router-link target="_blank" :to="disapproval.support_link_url" style=" color: #5e6b79;padding-left: 5px; font-size: 12px">Detaylı bilgi...</router-link>
                  <span v-if="disapproval.title == 'Internet Reklamcılığı Kriter Hatası'">

                      <h5 style="color: red; font-size: 13px;">Eğer iş ilanında alttaki hizmetlerin birinin olduğunu düşünüyorsan lütfen 'Internet Reklamcılığı Kriter Hatası şıkkını seçin</h5>

                    <ul style="padding-left: 42px;color:#2d3640; list-style: disc">
                        <li style="padding-top: 15px;">“100 Tanıtım Sitesinden Backlink “ gibi, site, blog vb. adı belirtilmeden yapılan çoklu backlink hizmeti</li>
                        <li style="padding-top: 15px;">Ekşi Sözlük’te tanıtım veya reklam hizmeti</li>
                        <li style="padding-top: 15px;">Takipçi, beğeni, üye satışı veya gönderimi</li>
                        <li style="padding-top: 15px;">Sosyal medya hesabı satışı</li>
                        <li style="padding-top: 15px;">“Sıralamada Yükselme Garantisi” veya “Google’da İlk Sıra” gibi abartılı ve gerçekçilikten uzak ifadeler</li>
                        <li style="padding-top: 15px;">Üye, takipçi vb. arttırmaya yönelik yazılım hizmetleri</li>
                    </ul>

                </span>




                <span v-if="disapproval.id === 369" style="color: red; font-size: 13px;"><br/>Eğer iletişim bilgisi siparişin gerçekleştirilebilmesi için gerekli ise, iletişim bilgisi butonuna basma.</span>
              </p>

              <p>
                <button :disabled="loading" @click="completeProcess(true)" v-if="selectedDisapprovalIds.length > 0" class="disapprove-button super-button" style="margin-top: 30px;">UYGUN DEĞİL</button>
              </p>
            </div>


            <!-- portfolio özel -->
            <div v-if="data.help_type === 'portfolio' || data.help_type === 'content'" style="float: left; margin-top: 24px; ">
              <div v-if="portfolioStep === 1">
                <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">Üstteki içerikte alttakilerden bir veya birkaç tanesi mevcut mudur?</h4>
                <p v-for="(disapproval, index) in data.disapprovals">
                  <button
                    :disabled="loading"
                    @click="selectDisapproval(index, disapproval.id)"
                    :class="[disapproval.selected ? 'selected' : '', 'editButton']"
                  >
                    Seç
                  </button>
                  <span style="color: #2d3640; font-weight: 500;">{{disapproval.title}}</span>
                  <router-link target="_blank" :to="disapproval.support_link_url" style="padding-left: 5px; font-size: 12px">Detay...</router-link>
                  <span v-if="disapproval.id === 368" style="color: red; font-size: 13px;"><br/>Uygunsuz içerik, tasarımı kötü içerik anlamına gelmemektedir. Daha fazla bilgi almak için üstteki "Detaylı bilgi..." linkine tıklayın. </span>

                </p>

                <p><button :disabled="loading" @click="completeProcess(false)" v-if="selectedDisapprovalIds.length > 0" class="disapprove-button super-button" style="margin-top: 30px;">UYGUN DEĞİL</button></p>
                <p><button :disabled="loading" @click="portfolioContinue(2)" v-if="! selectedDisapprovalIds.length" class="super-button-green" style="margin-top: 30px">MEVCUT DEĞİL, DEVAM</button></p>
              </div>

              <div v-if="portfolioStep === 2">
                <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">Yukarıdaki görsel/video en çok aşağıdaki seçeneklerden hangisine uyar?</h4>
                <p v-for="(scoreDisapproval , index) in data.scoreDisapprovals">
                  <button
                    :disabled="loading"
                    @click="selectScoreDisapproval(index, scoreDisapproval.id)"
                    :class="[scoreDisapproval.selected ? 'selectedScore' : '', 'scoreButton'+scoreDisapproval.importance]"
                  >
                    <span v-if="scoreDisapproval.importance === -1">Kötü</span>
                    <span v-if="scoreDisapproval.importance === -2" style="color: #000000">Orta</span>
                    <span v-if="scoreDisapproval.importance === -3">İyi</span>
                  </button>
                  <span style="color: #2d3640; font-weight: 500;">{{scoreDisapproval.title}}</span>
                  <router-link v-if="scoreDisapproval.support_link_url !== '/'" target="_blank" :to="scoreDisapproval.support_link_url" style="padding-left: 5px; font-size: 12px">Detay...</router-link>
                </p>
                <!-- ex
                <p><button :disabled="loading" @click="portfolioContinue(3)" v-if="! selectedDisapprovalIds.length && selectedScoreDisapprovalId" class="super-button-green" style="margin-top: 30px">DEVAM</button></p>
                -->
                <p><button  :disabled="loading" @click="completeProcess(true)" v-if="! selectedDisapprovalIds.length && selectedScoreDisapprovalId" class="super-button-green" style="margin-top: 30px">TAMAMLA</button></p>

              </div>



              <div v-if="portfolioStep === 3">
                <h4 style="margin-bottom:12px;font-size: 16px; font-weight: 400; color: #2d3640;">Yukaridaki görseli estetik açıdan değerlendirin:</h4>
                <p v-for="(portfolioScore , index) in data.portfolioScores">
                  <button
                    :disabled="loading"
                    @click="selectPortfolioScore(index, portfolioScore.id)"
                    :class="[portfolioScore.selected ? 'selectedScore' : '', 'scoreButton'+portfolioScore.importance]"
                  >
                    <span v-if="portfolioScore.importance === -1">Kötü</span>
                    <span v-if="portfolioScore.importance === -2" style="color: #000000">Orta</span>
                    <span v-if="portfolioScore.importance === -3">İyi</span>
                  </button>
                  <span style="color: #2d3640; font-weight: 500;">{{portfolioScore.title}}</span>
                  <router-link v-if="portfolioScore.support_link_url !== '/'" target="_blank" :to="portfolioScore.support_link_url" style="padding-left: 5px; font-size: 12px">Detay...</router-link>
                </p>
              </div>

            </div>



            <div v-if="data.help_type !== 'portfolio' && data.help_type !== 'content'" style="float: right; margin-top: 10px; margin-right: -20px;">
              <p><button  :disabled="loading" @click="completeProcess(true)" v-if="! selectedDisapprovalIds.length" class="super-button-green">UYGUN</button></p>
            </div>

            <div v-if="(data.help_type === 'portfolio' || data.help_type === 'content') && portfolioStep === 3 && selectedPortfolioScoreId" style="float: right; margin-top: 10px; margin-right: -20px;">
              <p><button  :disabled="loading" @click="completeProcess(true)" v-if="! selectedDisapprovalIds.length" class="super-button-green">UYGUN</button></p>
            </div>


            <div style="clear: both"></div>
          </div>

        </div>


        <div v-if="data.type === 'ticket'" class=" content-white" style="padding:32px; padding-bottom: 200px;">

          <div style="display: flex;  ">
            <div style="flex: 1">
              <button @click="goBack" :disabled="loading" class="editGreenButton hoverGray" style="background-color: #5e6b79">< Geri Dön</button>
            </div>
            <div style="flex: 1; text-align: center">
              <button @click="sendFeedback" class="editGreenButton editPinkButton" style="background-color: #ff0042" :disabled="loading">Hata veya Öneri Bildir</button>
            </div>
            <div style="flex: 1; text-align: right;">
              <button @click="unassign" class="editGreenButton"  :disabled="loading">Bu İşi Atla -></button>
            </div>
          </div>

          <div style="width: 700px; margin-top:44px; ">
            <div class="helpTypeLogicBurada" v-if="data.help_type === 'new_ticket'">
              <label style="font-size: 32px; margin-bottom: 10px; margin-top: 20px;">YENİ DESTEK MESAJI</label>

              <template v-if="!showTicketLastStep">
                <p style="font-weight: 600; font-size: 18px; margin-top: 30px; margin-bottom: 10px;"><span>Kullanıcı: </span> <a :href="'/'+data.info.user_username" target="_blank">{{data.info.user_username}}</a> </p>

                <div class="ticket-message" v-for="message in data.info.messages">
                  <div style="justify-content: space-between">
                    <p class="created">{{ message.created_at | customDateFormat('dd MMMM, yyyy - HH:mm') }}</p>
                    <p style="margin-bottom: 15px" v-html="message.message"></p>
                  </div>
                  <div v-if="message.uploads.length" style="margin-top: 30px">
                    <div v-for="upload in message.uploads" style="margin-top: 15px; margin-bottom: 8px; display: flex;">
                      <img style="height: 27px; width: 21px; " :src="upload.icon_png">
                      <a target="_blank" :href="upload.url" :download="upload.filename" style="color: #2d3640; padding-left: 8px;" >{{ upload.filename }}</a>
                    </div>
                  </div>
                </div>

                <div style="border-bottom: 1px solid #e1e1e1; margin-top: 30px"></div>

                <div style="display: flex; align-items: center; margin-top:40px; margin-bottom:10px">
                  <custom-select label="Hızlı Cevap Başlıkları"
                                 id="editor-question-categories"
                                 :options="selectedEditorTicketQuestionCategoryId ? [{title:'Seçimi Kaldır', id:null} , ...data.info.e_categories] : data.info.e_categories"
                                 :customOption="{name: 'title', value:'id'}"
                                 v-model="selectedEditorTicketQuestionCategoryId"
                                 style="width: 293px; "/>
                </div>


                <custom-select label="Hızlı Cevap Seçin..."
                               placeHolder="Örnek: Adobe XD veya Grafik Tasarımı"
                               id="skills"
                               inputType="search"
                               :options="skillSearchResults"
                               :customOption="{name: 'title', value:'id'}"
                               v-model="searchTermForEditorQuestion"
                               :exactMatchExists="true"
                               :suggestion-enabled="true"
                               @change="selectBoxOnChange"
                               style="width: 100%; margin-top:10px; margin-bottom: 10px"/>

                <p v-if="selectedEditorQuestion.id" style="font-size: 14px;">Seçilen Cevap: <span style="color: #fe5b5c">{{selectedEditorQuestion.title}}</span></p>
                <div class="hiddendiv"></div>
                <textarea v-model="messageWillSend" id="editor-comment-input" class="c-text-area" style=" width: 100%; height: 214px;" placeholder="Mesaj...">

                </textarea>
                <p style="cursor: pointer; font-size: 24px; width: 40px;" @click="showEmojiModal = true"> 🧐</p>

              </template>


              <div style="border-bottom: 1px solid #e1e1e1; margin-top: 30px"></div>



              <div style="display:flex; align-items: center; font-weight: 600; font-size: 18px; margin-top: 30px; margin-bottom: 10px;">
                <p>Ticket Kategorisi: </p>
                <select @change="changeCategoryId" v-model="categoryIdWillSend" class="super-drop" style="margin-left: 15px; background-position-x: 98%;" >
                  <option :value="item.id" v-for="item in data.info.ticket_categories">{{item.title}}</option>
                </select>
                <button @click="onPressChangeCategoryId" v-if="showChangeCategoryButton" style="margin-left:20px; height: 30px; " class="editGreenButton">Kategoriyi Değiştir</button>
              </div>
              <p style="font-size: 12px">Bu alanda eğer kullanıcının seçitiği kategorinin hatalı olduğunu düşünüyorsan, lütfen doğru kategoriyi seç.</p>


              <div v-if="!showTicketLastStep" style="margin-top: 50px; display: flex; flex-direction: column;  align-items: center; justify-content: center">
                <!--
                <button :disabled="loading" @click="showTicketLastStep = -1" class="super-action-button-blue">Ek Bilgi Gerekiyor</button>
                -->
                <button :disabled="loading || !selectedEditorTicketQuestionCategoryId" @click="showTicketLastStep = 1" class="super-button">Devam Et</button>
                <p v-if="!selectedEditorTicketQuestionCategoryId" style="font-size: 12px; color: #2d3640; font-weight: 500; margin-top: 10px;">Devam etmek için Hızlı Cevap Başlıkları bölümünde bir seçim yapman gerekiyor</p>
              </div>
              <div v-else style="margin-top: 50px; display: flex; flex-direction: column; justify-content: center">
                <template v-if="showTicketLastStep === 1">
                  <template>
                    <p style="color: #2d3640; font-weight: 300"><span style="color: #2d3640; font-weight: 600;">Seçimin:</span>
                      <span v-if="selectedEditorTicketQuestionCategoryId === 21">Destek mesajını kapatma, Bionluk inceleyecek</span>
                      <span v-else>Destek mesajını ayrıca kapat, Bionluk incelemeyecek.</span>
                    </p>
                  </template>
                  <p style="margin-top:15px; color: #2d3640; font-weight: 300"><span style="color: #2d3640; font-weight: 600;">Kullanıcıya Gönderilecek Mesaj:</span></p>
                  <p @click="showTicketLastStep = false" style="margin-bottom: 20px; font-size: 14px; color: #2cad87; font-weight: 800; margin-top: 5px" class="underline">[düzenle]</p>
                  <div style="white-space: pre-line; font-style: italic; font-size: 14px; margin-bottom: 50px">
                    {{messageWillSend}}
                  </div>
                </template>

                <div style="width: 200px; margin: 0 auto">
                  <button v-if="selectedEditorTicketQuestionCategoryId !== 21" :disabled="loading" @click="completeProcess(true)" class="super-button-green">Gönder & İşi Tamamla</button>
                  <button v-else :disabled="loading" @click="completeProcess(false)" class="super-button-green">Gönder ve İşi Tamamla</button>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div v-if="data.type === 'request'" class=" content-white" style="padding:32px; padding-bottom: 200px;">

          <div style="display: flex;  ">
            <div style="flex: 1">
              <button @click="goBack" :disabled="loading" class="editGreenButton hoverGray" style="background-color: #5e6b79">< Geri Dön</button>
            </div>
            <div style="flex: 1; text-align: center">
              <button @click="sendFeedback" class="editGreenButton editPinkButton" style="background-color: #ff0042" :disabled="loading">Hata veya Öneri Bildir</button>
            </div>
            <div style="flex: 1; text-align: right;">
              <button @click="unassign" class="editGreenButton"  :disabled="loading">Bu İşi Atla -></button>
            </div>
          </div>

          <div style="width: 700px; margin-top:44px; ">

            <div class="helpTypeLogicBurada">
              <label style="font-size: 32px; margin-bottom: 10px; margin-top: 20px;">Özel Alıcı İsteği</label>

              <p style="color: #2d3640; margin-top: 30px; margin-bottom: 10px;">Başlık</p>
              <input style="width: 400px" v-model="data.info.info.title" />


              <p style="color: #2d3640; margin-top: 30px;margin-bottom: 10px;">Açıklama</p>
              <textarea v-model="data.info.info.description" style="width: 777px; height:300px;font-size: 18px;"  >{{data.info.info.description}}</textarea>

              <div style="display: flex">
                <div>
                  <p style="color: #2d3640; margin-top: 30px; margin-bottom: 10px;">Kategori</p>
                  <select class="super-drop" v-model="data.info.category_id">
                    <option v-for="cat in categories" v-if="!cat.parent_id" :value="cat.id">{{cat.name}}</option>
                  </select>
                </div>
                <div style="margin-left: 40px;">
                  <p style="color: #2d3640; margin-top: 30px;  margin-bottom: 10px;">Alt Kategori</p>
                  <select v-model="data.info.category_sub_id" :disabled="!data.info.category_id" class="super-drop">
                    <option disabled :value='null'>Seç...</option>
                    <option v-for="subCategory in categories" v-if="data.info.category_id && (data.info.category_id === subCategory.parent_id)" :value="subCategory.id">
                      {{ subCategory.name  }}
                    </option>
                  </select>
                </div>


              </div>

              <div style="display: flex" v-if="data.info.category_sub_id && categories.find(({ id }) => id === data.info.category_sub_id) && categories.find(({ id }) => id === data.info.category_sub_id).category_service_types && categories.find(({ id }) => id === data.info.category_sub_id).category_service_types.length">
                <div>
                  <p style="color: #2d3640; margin-top: 30px;  margin-bottom: 10px;">Hizmet Türü</p>
                  <select v-model="data.info.category_service_type_id" :disabled="!data.info.category_sub_id" class="super-drop">
                    <option disabled :value='null'>Seçin...</option>
                    <option v-for="serviceType in categories.find(({ id }) => id === data.info.category_sub_id).category_service_types" :value="serviceType.id">
                      {{ serviceType.name  }}
                    </option>
                  </select>
                </div>
              </div>



              <p style="color: #2d3640; margin-top: 30px;margin-bottom: 10px;">Bütçe (TL)</p>
              <input readonly style="width: 200px" v-model="data.info.budget" />


              <div v-if="data.info.uploads && data.info.uploads.length > 0" class="upload-area-files">
                <p style="color: #2d3640; margin-top: 30px;margin-bottom: 10px;">Dosyalar</p>
                <div v-for="file in data.info.uploads">
                  <div style="display: flex; align-items: center; justify-content: space-between">
                    <div style="display: flex; align-items: center">
                      <img style="height: 25px; " :src="file.icon_png">
                      <div style="display: flex; flex-direction: column; margin-left: 15px;">
                        <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 16px; text-overflow: ellipsis; max-width: 240px; overflow: hidden; white-space: pre">{{ file.filename }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="!showTicketLastStep">
                <h4 style="margin-bottom:12px; margin-top:40px; font-size: 16px; font-weight: 400; color: #2d3640;">Alıcı isteğinde alttakilerden bir veya birkaç tanesi mevcut mudur?</h4>
                <p v-for="(disapproval, index) in data.disapprovals">
                  <button
                    :disabled="loading"
                    @click="selectDisapproval(index, disapproval.id)"
                    :class="[disapproval.selected ? 'selected' : '', 'editButton']">
                    Seç
                  </button>
                  <span style="color: #2d3640; font-weight: 500;">{{disapproval.title}}</span>
                  <router-link target="_blank" :to="disapproval.support_link_url" style=" color: #5e6b79;padding-left: 5px; font-size: 12px">Detaylı bilgi...</router-link>
                </p>
                <div>
                  <button v-if="selectedDisapprovalIds.length > 0" :disabled="loading" @click="completeProcess(false)" class="disapprove-button super-button" style="margin-top: 30px;">UYGUN DEĞİL</button>
                  <button v-else  :disabled="loading" @click="showTicketLastStep = true" class="super-button-green" style="margin-top: 30px;">MEVCUT DEĞİL, DEVAM</button>
                </div>
              </div>
              <div v-else style="width: 100%; margin-top: 40px;">
                <p @click="showTicketLastStep = false" style="margin-bottom: 20px; margin-bottom: 20px; font-size: 14px; color: #2cad87; font-weight: 500; margin-top: 5px" class="underline">geri dön</p>

                <div style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                  <input v-model="checkboxChecked" id="go-check" type="checkbox" size="30" style="transform: scale(2); cursor: pointer"/>
                  <label for="go-check" style="color: #2d3640; font-size: 13px; padding-left: 15px; padding-top: 3px; cursor: pointer">Başlık ve açıklamadaki yazım ve imla hatalarını düzelttim ve kategorinin doğruluğunu kontrol ettim.</label>
                </div>
                <div style="display: flex; justify-content: center; margin-top: 20px;">
                  <button :disabled="loading || !checkboxChecked || !enableButtonForRequest" @click="completeProcess(true)" class="super-button-green">İŞİ TAMAMLA</button>
                </div>
              </div>


            </div>

          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>

  import WorkstationNav from "../navComponent";
  import CustomSelect from "../../../components/CustomSelect";

  export default {
    name: "src-pages-body-workstation-sendOffer-v1",
    components: {
      WorkstationNav, CustomSelect
    },
    data() {
      return {
        checkboxChecked:false,
        searchTermForEditorQuestion:'',
        selectedEditorTicketQuestionCategoryId:null,
        skillSearchResults:[],
        selectedEditorQuestion: {},
        showTicketLastStep: false,

        showEmojiModal:false,
        activatedIframe:false,
        codecError: false,
        videoLoader:false,
        messageWillSend:'',
        categoryIdWillSend: null,
        showChangeCategoryButton: false,

	      selectedType:null,
	      categoryKinds:{
	      	type:'multiselect',
          name:'Seslendirme Dili',
          options:[

	          {
		          id:47,
		          name:'Türkçe',
		          selected:false
	          },
	          {
		          id:48,
		          name:'İngilizce',
		          selected:false
	          },
	          {
		          id:49,
		          name:'İspanyolca',
		          selected:false
	          },
	          {
		          id:50,
		          name:'Almanca',
		          selected:false
	          },
	          {
		          id:51,
		          name:'İtalyanca',
		          selected:false
	          },
	          {
		          id:52,
		          name:'Arapça',
		          selected:false
	          },
	          {
		          id:53,
		          name:'Diğer Diller',
		          selected:false
	          },
	          {
		          id:54,
		          name:'Fransızca',
		          selected:false
	          }
          ]
        },
	      sesTypes:[
		      {
			      id:101,
			      name:"Kadın Seslendirmen"
		      },
		      {
			      id:102,
			      name:"Erkek Seslendirmen"
		      }
	      ],
	      showSesDilFilter: false,



	      swiperOption: {
		      notNextTick: true,
		      direction: 'horizontal',
		      nextButton: '.swiper-button-next',
		      prevButton: '.swiper-button-prev',
		      slidesPerView: 1,
		      observeParents: true
	      },

        loading: false,
	      showLoader:false,

        portfolioStep:1,

        creditInfo: {
          total:{
            credit:0,
            tl:0
          },
          payed:{
            credit:0,
            tl:0
          },
          current:{
            credit:0,
            lt:0
          },
        },

        comment:'',
        selectedDisapprovalIds:[],
        selectedScoreDisapprovalId:null,
        selectedPortfolioScoreId:null,
        data: {
          portfolioStep:1,
          id:null,
          help_type:null,
          type:'home',
          info:{
            title: null,
            description:null,
            ticket_categories:[],
            portfolio:{
              file_type:null,
              image_url:null,
              image_url_original:null,
              image_url_small:null,
              portfolio_uuid:null,
              video_url:null,
            }
          },
          disapprovals:[],
          scoreDisapprovals:[],
          portfolioScores:[]
        },

        //new_ticket

        editorLogs:[],
        logsLimit:10,
        logsOffset:0,
        editorLogsseemorediv: false,


      }
    },

    methods: {
      turkishCharacterReplace(str) {
        str = str
          .replace(/ü/g, "u")
          .replace(/Ü/g, "U")
          .replace(/ö/g, "o")
          .replace(/Ö/g, "O")
          .replace(/ğ/g, "g")
          .replace(/Ğ/g, "G")
          .replace(/ş/g, "s")
          .replace(/Ş/g, "S")
          .replace(/ç/g, "c")
          .replace(/Ç/g, "C")
          .replace(/ı/g, "i")
          .replace(/İ/g, "I");

        return str;
      },
      selectBoxOnChange(e) {
        this.searchTermForEditorQuestion = '';
        this.selectedEditorQuestion = e;
        this.messageWillSend = e.text;
      },
      copyEmoji(text){
        navigator.clipboard.writeText(text).then(() => {
          let message =   'Kopyalandı! <span style="font-size:30px;">'+text+'</span>';
          this.$toasted.global.infoToast({description: message});
          this.showEmojiModal = false;
        }, function(err) {
          alert('Async: Could not copy text: ', err);
        });


      },
      changeCategoryId(e){

        if(e.target.value !== this.data.info.ticket_category_id){
          this.showChangeCategoryButton = true;
        }
      },

      onPressChangeCategoryId(){
        this.showChangeCategoryButton = false;
        this.$toasted.global.infoToast({description: 'Teşekkürler, ekstra kredi işi tamamladığında eklenecek.'});
      },


      deactivateIframeLoader() {
        this.videoLoader = false;
      },

      activateIframe() {
        this.videoLoader = true;
        this.activatedIframe = true;
      },




	    getLogsOffset(){

	    	this.logs(this.logsOffset);

        },

	    logs(offset){
	        this.loading = true;


	        this.api.content.getEditorLogs(offset)
		        .then(({data}) => {
			        let result = data;
			        if (result.success) {
		            this.data.type = 'logs';

		            this.editorLogs = this.editorLogs.concat(result.data.logs);
		            this.logsOffset = this.logsOffset + 10;
		            this.editorLogsseemorediv = result.data.seemorediv;


			        } else {
				        this.$toasted.global.errorToast({description: result.message});
			        }
			        this.loading = false;
		        })
		        .catch(err => {
			        this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
			        this.loading = false;
		        });


        },
      sendFeedback(){

        this.data.type = 'feedback';

      },
      portfolioContinue(step){

        this.portfolioStep = step;
      },

      showPortfolioDetailModal(portfolio) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: portfolio.uuid}});
      },

      selectScoreDisapproval(index, id){


        this.data.scoreDisapprovals[0].selected = false;
        if(this.data.scoreDisapprovals.length > 1){
          this.data.scoreDisapprovals[1].selected = false;
        }
        if(this.data.scoreDisapprovals.length > 2){
          this.data.scoreDisapprovals[1].selected = false;
          this.data.scoreDisapprovals[2].selected = false;
        }


        this.selectedScoreDisapprovalId = id;
        this.data.scoreDisapprovals[index].selected = true;

      },

      selectPortfolioScore(index, id){


        this.data.portfolioScores[0].selected = false;
        this.data.portfolioScores[1].selected = false;
        if(this.data.portfolioScores.length > 2){
          this.data.portfolioScores[2].selected = false;
        }


        this.selectedPortfolioScoreId = id;
        this.data.portfolioScores[index].selected = true;

      },

      selectDisapproval(index, id){

          if(! this.data.disapprovals[index].selected){

            this.selectedDisapprovalIds.push(id)
          } else {
            let selectedIdsIndex = this.selectedDisapprovalIds.indexOf(id);
            if (index > -1) {
              this.selectedDisapprovalIds.splice(selectedIdsIndex, 1);
            }
          }

          this.data.disapprovals[index].selected = ! this.data.disapprovals[index].selected;
      },

      openModal(offer) {
        if (offer.type === "offer") {
          this.getOfferDetail(offer);
        }

        if (offer.type === "request") {
          this.getOfferDetail(offer, offer.type);
        }

      },

      start(){


        this.activatedIframe = false;
        this.codecError = false;
        this.videoLoader = false;

        this.loading = true;

        this.api.content.newEditorTask()
          .then(({data}) => {
            let result = data;
            if (result.success) {

              if(result.data.type === 'ses_filter'){
	              let arr = [];
	              let portfolioPartArr = [];
	              result.data.info.portfolios.forEach((portfolio, index) => {
		              portfolio.activatedIframe = false;
		              if (arr.length < 2) {
			              arr.push(portfolio);
		              } else {
			              portfolioPartArr.push(JSON.parse(JSON.stringify(arr)));
			              arr = [];
			              arr.push(portfolio);
		              }
		              if (index === result.data.info.portfolios.length - 1) {
			              portfolioPartArr.push(JSON.parse(JSON.stringify(arr)));
		              }
	              });
              }

              this.showTicketLastStep = false;
              if(result.data.type === 'ticket'){
                this.categoryIdWillSend = result.data.info.ticket_category_id;
                this.skillSearchResults = result.data.info.e_questions;
                this.searchTermForEditorQuestion = '';
                this.selectedEditorTicketQuestionCategoryId = null;
              }

	            this.data = result.data;
              this.checkboxChecked = false;
	            this.portfolioStep = 1;
	            this.selectedDisapprovalIds= [];
	            this.selectedScoreDisapprovalId= null;
	            this.selectedPortfolioScoreId = null;
	            this.messageWillSend = '';
              this.selectedEditorQuestion = {};
	            this.comment = '';
	            this.selectedType = null;

              if(result.data.type === 'gig'
                && result.data.help_type === 'content'
                && result.data.info?.content?.file_type === 'video'
                && result.data.info?.content?.video_url === null
              ){
                this.codecError = true;
              }

	            this.categoryKinds =   {
		            type:'multiselect',
		            name:'Seslendirme Dili',
		            options:[

			            {
				            id:47,
				            name:'Türkçe',
				            selected:false
			            },
			            {
				            id:48,
				            name:'İngilizce',
				            selected:false
			            },
			            {
				            id:49,
				            name:'İspanyolca',
				            selected:false
			            },
			            {
				            id:50,
				            name:'Almanca',
				            selected:false
			            },
			            {
				            id:51,
				            name:'İtalyanca',
				            selected:false
			            },
			            {
				            id:52,
				            name:'Arapça',
				            selected:false
			            },
			            {
				            id:53,
				            name:'Diğer Diller',
				            selected:false
			            },
			            {
				            id:54,
				            name:'Fransızca',
				            selected:false
			            }
		            ]
	            },

	            window.scroll(0,0);

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.loading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loading = false;
          });

      },


      unassign(){

        this.loading = true;

        this.api.content.unassignEditorTask(this.data.id, this.comment)
          .then(({data}) => {
            let result = data;
            if (result.success) {

              if(this.comment && this.comment.length > 1){
                let earned_credit = result.data.earned_credit;
                let earned_tl = result.data.earned_tl;
                this.creditInfo.total.credit = this.creditInfo.total.credit + earned_credit;
                this.creditInfo.current.credit = this.creditInfo.current.credit + earned_credit;

                this.creditInfo.total.tl = this.creditInfo.total.tl + earned_tl;
                this.creditInfo.current.tl = this.creditInfo.current.tl + earned_tl;

                this.$toasted.global.infoToast({description: result.message});
              }

              this.checkboxChecked = false;
              this.data = {
                portfolioStep:1,
                id:null,
                help_type:null,
                type:'home',
                info:{
                  title: null,
                  description:null,
                  portfolio:{
                    file_type:null,
                    image_url:null,
                    image_url_original:null,
                    image_url_small:null,
                    portfolio_uuid:null,
                    video_url:null,
                  }
                },
                disapprovals:[],
                scoreDisapprovals:[],
                portfolioScores:[],
              };
              this.selectedDisapprovalIds = [];
              this.selectedScoreDisapprovalId = null;
              this.selectedPortfolioScoreId = null;
              this.searchTermForEditorQuestion = '';
              this.skillSearchResults = [];
              this.selectedEditorTicketQuestionCategoryId = null;
              this.comment = '';
	            this.selectedType = null;
              this.selectedEditorQuestion = {};
              this.messageWillSend = '';
              this.categoryKinds =   {
	              type:'multiselect',
	              name:'Seslendirme Dili',
	              options:[

		              {
			              id:47,
			              name:'Türkçe',
			              selected:false
		              },
		              {
			              id:48,
			              name:'İngilizce',
			              selected:false
		              },
		              {
			              id:49,
			              name:'İspanyolca',
			              selected:false
		              },
		              {
			              id:50,
			              name:'Almanca',
			              selected:false
		              },
		              {
			              id:51,
			              name:'İtalyanca',
			              selected:false
		              },
		              {
			              id:52,
			              name:'Arapça',
			              selected:false
		              },
		              {
			              id:53,
			              name:'Diğer Diller',
			              selected:false
		              },
		              {
			              id:54,
			              name:'Fransızca',
			              selected:false
		              }
	              ]
              },



              this.loading = false;


              window.scroll(0,0);

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
            this.loading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loading = false;
          });

      },



      completeProcess(result){


        let json_obj = this.data.type === 'request' ?
          {
            title: this.data.info.info.title,
            description: this.data.info.info.description,
            category_id: this.data.info.category_id,
            category_sub_id: this.data.info.category_sub_id,
            category_service_type_id: this.data.info.category_service_type_id,
          }
          : {
        	message: this.messageWillSend,
          category_id: this.categoryIdWillSend,
          external_id: this.selectedEditorQuestion ? this.selectedEditorQuestion.id : null
        }


	      this.loading = true;

        this.api.content.completeTaskEditor(this.data.id, result, JSON.stringify(this.selectedDisapprovalIds), this.selectedScoreDisapprovalId, this.selectedPortfolioScoreId, JSON.stringify(json_obj))
          .then(({data}) => {
            let result = data;
            if (result.success) {

              let earned_credit = result.data.earned_credit;
              let earned_tl = result.data.earned_tl;
              this.creditInfo.total.credit = this.creditInfo.total.credit + earned_credit;
              this.creditInfo.current.credit = this.creditInfo.current.credit + earned_credit;

              this.showTicketLastStep
              this.creditInfo.total.tl = this.creditInfo.total.tl + earned_tl;
              this.creditInfo.current.tl = this.creditInfo.current.tl + earned_tl;

              this.checkboxChecked = false;
              this.data = {
                id: null,
                help_type: null,
                type: 'home',
                info:{
                  title: null,
                  description:null,
                  portfolio:{
                    file_type:null,
                    image_url:null,
                    image_url_original:null,
                    image_url_small:null,
                    portfolio_uuid:null,
                    video_url:null,
                  }
                },
              };

              window.scroll(0,0);
              this.$toasted.global.infoToast({description: result.message});

	            this.selectedType = null;
	            this.categoryKinds =   {
		            type:'multiselect',
		            name:'Seslendirme Dili',
		            options:[

			            {
				            id:47,
				            name:'Türkçe',
				            selected:false
			            },
			            {
				            id:48,
				            name:'İngilizce',
				            selected:false
			            },
			            {
				            id:49,
				            name:'İspanyolca',
				            selected:false
			            },
			            {
				            id:50,
				            name:'Almanca',
				            selected:false
			            },
			            {
				            id:51,
				            name:'İtalyanca',
				            selected:false
			            },
			            {
				            id:52,
				            name:'Arapça',
				            selected:false
			            },
			            {
				            id:53,
				            name:'Diğer Diller',
				            selected:false
			            },
			            {
				            id:54,
				            name:'Fransızca',
				            selected:false
			            }
		            ]
	            },
              this.start();
            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.loading = false;
            }

          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loading = false;

          });

      },

      goBack(){
        this.loading = true;

        this.portfolioStep = 1;
        this.checkboxChecked = false;
        this.data = {
          id:null,
          help_type:null,
          type:'home',
          info:{
            title: null,
            description:null,
            portfolio:{
              file_type:null,
              image_url:null,
              image_url_original:null,
              image_url_small:null,
              portfolio_uuid:null,
              video_url:null,
            }
          },
          disapprovals:[],
          scoreDisapprovals:[],
          portfolioScores:[]
        };

        this.selectedDisapprovalIds = [];
        this.selectedScoreDisapprovalId = null;
	      this.selectedType = null;
	      this.categoryKinds =   {
		      type:'multiselect',
		      name:'Seslendirme Dili',
		      options:[

			      {
				      id:47,
				      name:'Türkçe',
				      selected:false
			      },
			      {
				      id:48,
				      name:'İngilizce',
				      selected:false
			      },
			      {
				      id:49,
				      name:'İspanyolca',
				      selected:false
			      },
			      {
				      id:50,
				      name:'Almanca',
				      selected:false
			      },
			      {
				      id:51,
				      name:'İtalyanca',
				      selected:false
			      },
			      {
				      id:52,
				      name:'Arapça',
				      selected:false
			      },
			      {
				      id:53,
				      name:'Diğer Diller',
				      selected:false
			      },
			      {
				      id:54,
				      name:'Fransızca',
				      selected:false
			      }
		      ]
	      },

        this.loading = false;

      },


      init(){
        this.api.content.initEditor()
          .then(({data}) => {
            let result = data;
            if (result.success) {


              this.creditInfo = result.data;
              window.scroll(0,0);

            } else {
              this.$toasted.global.errorToast({description: result.message});
              this.$router.push('/');
            }
            this.loading = false;
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
            this.loading = false;
            this.$router.push('/');
          });
      }
    },

    watch:{
      'data.info.category_id': function(newVal, oldVal){
        if(newVal && oldVal && newVal !== oldVal && this.data.type === 'request'){
          this.data.info.category_sub_id = null;
          this.data.info.category_service_type_id = null;
        }
      },
      'data.info.category_sub_id': function(newVal, oldVal){
        if(newVal && oldVal && newVal !== oldVal && this.data.type === 'request'){
          this.data.info.category_service_type_id = null;
        }
      },
      showTicketLastStep: function(){
        if(this.data.type === 'ticket'){
          window.scroll(0,0);
        }
      },
      searchTermForEditorQuestion: function(newValue, oldValue) {
        if(this.data.type === 'ticket' && this.data.help_type === 'new_ticket'){
          let searchValue = newValue ? newValue.toLowerCase() : '';
          if (0 < searchValue.length && searchValue.length < 20) {
            let arr = [];

            let filteredArr = this.selectedEditorTicketQuestionCategoryId ? this.data.info.e_questions.filter(question => question.category_id === this.selectedEditorTicketQuestionCategoryId) : this.data.info.e_questions;

            filteredArr.map((skill) => {
              let skillName = skill.title.toLowerCase()
              if (skillName.includes(searchValue)) {
                arr.push(skill);
              }
            });


            this.skillSearchResults = arr;

          } else {
            this.skillSearchResults = this.data.info.e_questions
          }
        }
      },
      selectedEditorTicketQuestionCategoryId: function (newVal) {
        if(this.data.type === 'ticket' && this.data.help_type === 'new_ticket'){

          this.skillSearchResults = this.selectedEditorTicketQuestionCategoryId ? this.data.info.e_questions.filter(question => question.category_id === this.selectedEditorTicketQuestionCategoryId) : this.data.info.e_questions;
          this.searchTermForEditorQuestion = '';
        }
      }
    },
    computed: {
      enableButtonForRequest() {
        if(this.data && this.data.type === 'request'){
          return this.data.info.category_id && this.data.info.category_sub_id
        } else {
          return true;
        }
      },
	    swiper() {
		    return this.$refs.mySwiper.swiper
	    },
    },

    async created() {
      await this.$store.state.categoriesPromise
      this.init()
    }
  }


</script>

<style scoped lang="scss">

  .upload-area-files{
    width: 663px;

    .upload-files-title{
      font-size: 18px;
      font-weight: 600;
      color: #5e6b79;
    }
    .upload-file-count{
      font-size: 16px;
      font-weight: 500;
      color: #5e6b79;
    }

  }

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
    .modal-container{
      font-family: 'sofia-pro';
      box-sizing: border-box;
      width: calc(100vw - 20px) !important;
      max-height: 640px;
      max-width: 770px;
      background-color: #ffffff;
      box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
      position: relative;
      padding: 50px 140px 50px 140px;
    }
    .close-div {
      z-index: 1;
      position: absolute;
      top: 20px;
      right: 20px;
      opacity: 0.75;
    }

    .close-div:hover {
      opacity: 1;
    }

    .modal-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .modal-header {
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      margin-bottom: 30px;
      color: #2d3640;

      span {
        font-weight: 600;
      }
    }
  }

  /*iframe*/
  .playIcon {
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 75px;
    width: 75px;
    border: 2px solid white;
    background-color: white;
    border-radius: 50%;
  }

  .hoveropa {
    height: 75px;
    width: 75px;
  }


  .chatrow {
    height: 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #eaedf2;
    &:nth-child(2) {
      border-top: solid 1px #eaedf2;
    }
  }

  .numberCircle {

    border-radius: 50%;
    width: 36px;
    font-size: 28px;
    border: 2px solid #2d3640;

  }

  .numberCircle span {

    text-align: center;
    line-height: 36px;
    display: block;
    color: #5e6b79;
  }

  .content-header-available-cash {
    display: inline-flex;
    width: 271px;
    height: 40px;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }

  .content-header-text {
    font-size: 24px;
    font-weight: 600;
    color: #2d3640;
  }

  .content-header {
    margin-top: 25px;
    margin-bottom: 13px;
    width: 849px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    justify-content: space-between;
  }

  .content-header-available-cash-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #2d3640;
  }

  .withdrawfundsModalTrigger {
    font-size: 14px;
    line-height: 1.29;
    color: #2d3640;
    padding-top: 2px;
  }

  .super-button-light-small {
    width: 100px;
    height: 40px;
    display: flex;
    padding: 0;
    justify-content: center;
  }

  .title {
    font-size: 14px;
    color: #8b95a1;
    padding-bottom: 16.5px;
  }

  .chatrow-item {
    padding: 0;
    color: #2d3640;
  }

  .chatrow-item-date {
    line-height: 1.12;
    color: #2d3640;
  }

  .chatrow-item-description {
    font-weight: 500;
    line-height: 1.12;
    color: #2d3640;
  }

  .chatrow-item-status {
    line-height: 1.12;
    color: #2d3640;
  }

  .chatrow-item-total {
    line-height: 1.12;
  }

  .content-white {
    padding: 0;
    width: 100%;
    margin-bottom: 100px;
  }

  .add-credit-button {
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    border: solid 1px #2d3640;
  }


  .editButton {
    margin-top:15px; margin-right:8px;font-size:12px;height:32px;padding: 2px 5px 4px; min-width: 50px!important; max-width: 500px!important; width: auto!important; border-radius: 5px!important;
  }

  .selected {
    background-color: #2d3640;
    color:#FFFFFF;
  }


  .selected:hover {
    background-color: #2d3640!important;
    color:#FFFFFF;
  }


  .disapprove-button {
    background-color: #fd4056!important;
  }

  .disapprove-button:hover {
    background-color: #ff0042!important;
  }

  .hoverGray:hover {
    background-color: #35495e!important;
  }



  .user-portfolio-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 27px;
    width: 855px;
    .portfolio-array {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

  }

  .user-portfolio {
    background-color: #ffffff;
    border: solid 1px #eaedf2;
    padding: 20px;
    &:nth-child(2n) {
      margin-left: 30px;
    }

    &:nth-child(n+3) {
      margin-top: 30px;
    }

    .user-portfolio-image {

    }

    .user-portfolio-name {
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      color: #4b4f52;
      margin-top: 21px;
      max-width: 370px;
    }
  }


  .user-portfolio-video-icon {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 50%;
    width: 75px;
    height: 75px;
  }

  .scoreButton-1{
    margin-top:15px; margin-right:8px;font-size:12px;height:32px;padding: 2px 5px 4px; min-width: 50px!important; max-width: 500px!important; width: auto!important; border-radius: 5px!important;

    background-color: red!important;
    color:white!important;
    opacity: 0.5;
  }

  .scoreButton-1:hover{
    opacity: 0.7;
  }


  .scoreButton-2{

    margin-top:15px; margin-right:8px;font-size:12px;height:32px;padding: 2px 5px 4px; min-width: 50px!important; max-width: 500px!important; width: auto!important; border-radius: 5px!important;


    background-color: orange!important;
    color:white!important;
    opacity: 0.5;
    font-weight: 600;
  }

  .scoreButton-2:hover{
    opacity: 0.7;
  }


  .scoreButton-3{
    margin-top:15px; margin-right:8px;font-size:12px;height:32px;padding: 2px 5px 4px; min-width: 50px!important; max-width: 500px!important; width: auto!important; border-radius: 5px!important;

    background-color: green!important;
    color:white!important;
    opacity: 0.5;
    font-weight: 600;
  }

  .scoreButton-3:hover{
    opacity: 0.7;
  }




  .selectedScore{

    opacity: 1!important;

  }

  .editPinkButton:hover {
    background-color: #fd4056!important;
  }



  .ticket-message{
    margin-top: 20px;
    line-height: 1.86;
    color: #2d3740;
    width: 743px;
    padding: 15px 23px;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    text-align: left;
    background-color: rgba(163, 224, 225, 0.5);
    .created{
      text-align: right;
      font-size: 14px;
      color: #5e6b79;
    }
  }


  .underline{
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
</style>
